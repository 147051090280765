
@import url("https://p.typekit.net/p.css?s=1&k=xda6knk&ht=tk&f=6808.6846.15498.15501.15505.15510.26839.26840.26841.26842.28900.28901.28904.28905&a=72629252&app=typekit&e=css");


@font-face {
    font-family: "myriad-pro";
    src: url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

.tk-myriad-pro {
    font-family: "myriad-pro",sans-serif;
}

.tk-roboto {
    font-family: "roboto",sans-serif;
}

body {
}

.layoutWrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: myriad-pro;
}

.layoutTopNav {
    flex-shrink: 0;
    width: 100%;
    background-color: #004270;
}

.table td {
    border-top: none !important; 
}

.table th {
    border: none !important;
    padding: 15px;
}


.layoutContent {
    background-color: #EFEFEF;
    flex: 1 0 auto;
    line-height: 1;
}

.layoutFooter {
    height: 50px;
    flex-shrink: 0;
    background-color: #555555;
}

.mainLogo {
    border: none;
}

.queueStatsWrap {
    padding: 5px;
}

.callQueueWrap {
    height: 100%;
    padding: 0px 0px 20px 0px;
    margin: 10px 0px 10px 0px;
}
.callTableWrap {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #dddddd;
    font-family: Roboto;
    height: 100%;
    padding: 10px 10px 0px 10px;
}

.agentTableWrap {
    background-color: white;
    margin: 10px 0px 10px 0px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #dddddd;
    font-family:Roboto;
}

.agentDataRow {
    font-size: 20px;
    color: #555555;
    padding: 5px;
}
.agentDataRowTablet {
    font-size: 18px;
    color: #555555;
    padding: 5px;
}
.agentStatusWrap {
    font-weight: normal;
    font-family: Roboto;
    width: 150px;
    height: auto;
    font-size: 16px;
}

.agentAvailable {
    background-color: #13A085;
    border-radius: 20px;
    color: whitesmoke;
    padding: 5px 10px 5px 10px;
}
.agentOther {
    background-color: #555555;
    border-radius: 20px;
    color: whitesmoke;
    padding: 5px 10px 5px 10px;
}
.agentOnCall {
    background-color: #BD202A;
    border-radius: 20px;
    color: whitesmoke;
    padding: 5px 10px 5px 10px;
}
.blueColHeader {
    color: #004270;
    font-size: 20px;
    font-family: myriad-pro;
    font-weight: bold;
}
.blueColHeaderTablet {
    color: #004270;
    font-size: 20px;
    font-family: myriad-pro;
    font-weight: bold;
}
th.blueColHeader {
    color: #004270;
    font-size: 20px;
    font-family: Roboto;
    font-weight: normal;
}

th.blueColHeaderTablet {
    color: #004270;
    font-size: 20px;
    font-family: Roboto;
    font-weight: normal;
}


.statsRegular {
    color: #555555;
    background-color: white;
    border-radius: 10px;
    border: medium;
    width: auto;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
    box-shadow: 0px 0px 10px #dddddd;
}
.statsRegular > h2 {
    padding: 15px;
    font-family: Roboto;
}
.statsPositive {
    background-color: #13A085;
    border-radius: 10px;
    border: medium;
    width: auto;
    height: auto;
    color: whitesmoke;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
    box-shadow: 0px 0px 10px #dddddd;
}
.statsPositive > h2 {
    padding: 15px;
    font-family: Roboto;
}

.statsNegative {
    background-color: #BD202A;
    border-radius: 10px;
    border: medium;
    width: auto;
    height: auto;
    color: whitesmoke;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
    box-shadow: 0px 0px 10px #dddddd;
}
.statsNegative > h2 {
    padding: 15px;
    font-family: Roboto;
}

.statsDataFormat {
    font-size: 100px;
    font-weight: bold;
    justify-content: center
}

.tableTitle {
    color: #004270;
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    font-family: myriad-pro;
    padding: 15px 15px 15px 15px;
}

.tableTitleMobile {
    color: #004270;
    font-weight: bold;
    font-size: 26px;
    text-align: center
}

.mobileDataContainer {
    background-color: whitesmoke;
    border-radius: 5px;
    border: 2px black;
    width: auto;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    border-style: solid
}

.mobileHeader {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    padding: 1px 5px 1px 5px
}

.mobileCallerInfo {
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    padding: 2px 5px 2px 10px;
}
.mobileCallNum {
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    padding: 1px 5px 1px 5px
}
.mobileCallWait {
    font-weight: normal;
    font-size: 16px;
    text-align: right;
    padding: 1px 5px 1px 5px
}

.mobileData {
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    padding: 1px 5px 1px 5px
}

.agentAvailableMobile {
    background-color: #13A085;
    color: whitesmoke;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding: 2px 5px 2px 10px;
}

.agentOtherMobile {
    background-color: #555555;
    color: whitesmoke;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding: 2px 5px 2px 10px;
}

.agentOnCallMobile {
    background-color: #BD202A;
    color: whitesmoke;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding: 2px 5px 2px 10px;
}

.btn-primary {
    background-color: #004270;
    border-color: #004270;
}